<template>
  <div>
    <v-chip
      v-if="isActive && isBbcsService && isWho"
      :to="{
        name: 'bbcs-details',
        params: { bbcsProductId: product.id },
      }"
      color="secondary"
      class="my-1 mr-1"
      link
    >
      {{ $t("BBCS internet") }}
    </v-chip>
    <v-chip
      v-if="isActive && isMetronetService && isWho"
      :to="{
        name: 'metronet-details',
        params: { metronetProductId: product.id },
      }"
      color="secondary"
      class="my-1 mr-1"
      link
    >
      {{ $t("Metronet internet") }}
    </v-chip>
    <v-chip
      v-if="isActive && isReverseDns"
      :to="{
        name: 'reverse-dns-service',
        params: { productId: product.id },
      }"
      color="secondary"
      class="my-1 mr-1"
      link
    >
      {{ $t("Reverse DNS") }}
    </v-chip>
    <v-chip
      v-if="isActive && isTrafficStats"
      :to="{
        name: 'rzo-traffic-stats',
        params: { productId: product.id },
      }"
      color="secondary"
      class="my-1 mr-1"
      link
    >
      {{ $t("Traffic stats") }}
    </v-chip>
    <v-chip
      v-if="isActive && isAccessLog"
      :to="{
        name: 'rzo-access-logs',
        params: { productId: product.id },
      }"
      color="secondary"
      class="my-1 mr-1"
      link
    >
      {{ $t("Access logs") }}
    </v-chip>
    <v-chip
      v-if="isActive && isPowerStats"
      :to="{
        name: 'rzo-power-stats',
        params: { productId: product.id },
      }"
      color="secondary"
      class="my-1 mr-1"
      link
    >
      {{ $t("Power stats") }}
    </v-chip>
    <v-chip
      v-if="
        isActive && (isVoipService || isMobileService) && isTelephoneService
      "
      :to="{
        name: 'phone-service-details',
        params: { productId: product.id },
      }"
      color="secondary"
      class="my-1 mr-1"
      link
    >
      {{ $t("Telephony") }}
    </v-chip>
    <v-chip
      v-if="isActive && isTVService"
      :to="{
        name: 'zuma-tv',
        params: { productId: product.id },
      }"
      color="secondary"
      class="my-1 mr-1"
      link
    >
      {{ $t("Television") }}
    </v-chip>
    <v-chip
      v-if="isActive && isDomainRegService"
      :to="{
        name: 'domain-service',
        params: { productId: product.id },
      }"
      color="secondary"
      class="my-1 mr-1"
      link
    >
      {{ $t("Domain") }}
    </v-chip>
    <v-chip
      v-else-if="isActive && domainName"
      :to="{
        name: 'domain-service',
        params: { domainName: domainName },
      }"
      color="secondary"
      class="my-1 mr-1"
      link
    >
      {{ $t("Domain") }}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: "ProductTags",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isTelephoneService: false,
  }),
  computed: {
    isActive() {
      return this.product != null && this.product.no_billing == false;
    },
    profile() {
      var profiles = this.product.variants.filter(
        (variant) => variant.component_code == "PF"
      );
      return profiles.length > 0 ? profiles[0] : null;
    },
    isWho() {
      return (
        this.$store.getters.isReady &&
        this.$store.state.session.partnerType == "WHO"
      );
    },
    isBbcsService() {
      return (
        this.$config.mandant == "iway" &&
        (this.product.tags.includes("xdsl") ||
          (this.product.tags.includes("fiber") &&
            this.profile != null &&
            this.profile.tags.includes("bbcs")))
      );
    },
    isMetronetService() {
      return (
        this.$config.mandant == "iway" &&
        this.product.tags.includes("fiber") &&
        this.profile != null &&
        this.profile.tags.includes("metronet")
      );
    },
    isReverseDns() {
      return (
        this.product.variants.filter(function (variant) {
          return variant.tags.includes("fix-ip");
        }).length > 0
      );
    },
    isTrafficStats() {
      return (
        this.$config.mandant == "rzo" &&
        this.product.tags.includes("rack-space")
      );
    },
    isAccessLog() {
      return (
        this.$config.mandant == "rzo" &&
        this.product.tags.includes("rack-space")
      );
    },
    isPowerStats() {
      return (
        this.$config.mandant == "rzo" &&
        this.product.tags.includes("rack-space")
      );
    },
    isVoipService() {
      return (
        this.$config.mandant == "iway" && this.product.tags.includes("voip")
      );
    },
    isTVService() {
      return (
        this.$config.mandant == "iway" &&
        this.product.tags.includes("tv") &&
        this.$store.state.session.partnerType === "WHO"
      );
    },
    isMobileService() {
      return (
        this.$config.mandant == "iway" && this.product.tags.includes("mobile")
      );
    },
    isDomainRegService() {
      return (
        this.$config.mandant == "iway" &&
        this.product.tags.includes("domain-reg")
      );
    },
    domainName() {
      this.product;
      for (let i = 0; i < this.product.variants.length; i++) {
        let variant = this.product.variants[i];
        for (let j = 0; j < variant.attributes.length; j++) {
          let attr = variant.attributes[j];
          if (attr.attr_code == "DOM") return attr.attr_value;
        }
      }
      return null;
    },
  },
  methods: {
    checkTelephoneService() {
      var that = this;
      this.isTelephoneService = false;
      if (this.isVoipService || this.isMobileService) {
        this.$http
          .get("services/phone/" + this.product.id, {
            disableDefaultErrorHandler: true,
          })
          .then((response) => {
            that.isTelephoneService = true;
          })
          .catch((err) => {
            if (err.status == 403 || err.status == 404) {
              console.log(
                "user or person seems not allowed to see telephone services"
              );
            } else
              that.$store.commit("setSystemError", {
                msg: err.message,
                title: err.title,
              });
          });
      }
    },
  },
  mounted() {
    this.checkTelephoneService();
  },
};
</script>

<i18n>
{
  "en": {
    "BBCS internet": "BBCS internet",
    "Metronet internet": "Metronet internet",
    "Reverse DNS": "Reverse DNS",
    "Traffic stats": "Traffic stats",
    "Access logs": "Access logs",
    "Power stats": "Power stats",
    "Telephony": "Telephony",
    "Domain": "Domain",
    "Television": "TV"
  },
  "de": {
    "BBCS internet": "BBCS Internet",
    "Metronet internet": "Stadtnetz Internet",
    "Reverse DNS": "Reverse DNS",
    "Traffic stats": "Traffic Statistik",
    "Access logs": "Zugangsprotokoll",
    "Power stats": "Strom Statistik",
    "Telephony": "Telefonie",
    "Domain": "Domain",
    "Television": "TV"
  }
}
</i18n>
