export default {
  methods: {
    billingPeriodToFactor(period) {
      switch (period) {
        case "MIV":
        case "WHO-MIV":
        case "ER":
        case "WHO-ER":
        case "EN":
        case "WHO-EN":
          return 1.000;
        case "QIV":
        case "WHO-QIV":
          return 3.000;
        case "HIV":
        case "WHO-HIV":
          return 6.000;
        case "JIV":
        case "WHO-JIV":
          return 12.000;
        default:
          return 1.000;
      }
    },
    getBillingNextDate(product) {
      if (
        product.billing_end_date &&
        product.billing_next_date &&
        this.$moment(product.billing_next_date).isAfter(product.billing_end_date)
      ) {
        return null;
      } else {
        return product.billing_next_date;
      }
    },
    getMonthyProductPrice(product) {
      return (
        product.amount / this.billingPeriodToFactor(
          product.billing_period) * 20.000
      ).toFixed(0) * 0.050;
    },
    getMonthyProductPriceIncVat(product) {
      return (
        product.amount / this.billingPeriodToFactor(
          product.billing_period) * (100.000 + product.vat) * 0.010 * 20.000
      ).toFixed(0) * 0.050;
    },
    getProductPriceIncVat(product) {
      return (
        product.amount * (100.000 + product.vat) * 0.010 * 20.000
      ).toFixed(0) * 0.050;
    },
  }
};
