<template>
  <div>
    <v-chip
      :class="
        status == 'terminated' || status == 'done'
          ? 'red darken-2 white--text my-1 mr-1'
          : status == 'in-termination'
          ? 'amber darken-3 white--text my-1 mr-1'
          : 'green darken-3 white--text my-1 mr-1'
      "
      :small="small"
    >
      {{ $t(status) }}
    </v-chip>
    <v-chip
      class="deep-orange grey--text text--lighten-2 my-1 mr-1"
      :small="small"
      v-if="blocked"
    >
      {{ $t("blocked") }}
      <v-icon small right class="grey--text text--lighten-2">
        mdi-power-plug-off
      </v-icon>
    </v-chip>
  </div>
</template>

<script>
import ProductTools from "@/utils/mixins/productTools";

export default {
  name: "ProductStatus",
  mixins: [ProductTools],
  props: {
    product: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    now: null,
  }),
  watch: {
    product: {
      immediate: true,
      handler(value) {
        this.now = new Date();
      },
    },
  },
  computed: {
    status() {
      // setup (unique)
      if (["ER", "WHO-ER"].includes(this.product.billing_period))
        if (this.product.no_billing) return "done";
        else return "activated";
      // unique
      else if (["EN", "WHO-EN"].includes(this.product.billing_period))
        if (this.product.no_billing) return "done";
        else return "progress";
      else if (
        this.product.billing_end_date &&
        (this.$moment(this.now).isBefore(this.product.billing_end_date) ||
          this.$moment(this.now).isSame(this.product.billing_end_date))
      ) {
        return "in-termination";
      } else if (
        this.product.billing_end_date &&
        this.$moment(this.now).isAfter(this.product.billing_end_date)
      ) {
        return "terminated";
      } else {
        return "active";
      }
    },
    blocked() {
      return this.product.blocked;
    },
  },
};
</script>

<i18n>
{
  "en": {
    "terminated": "terminated",
    "in-termination": "in termination",
    "active": "active",
    "prepared": "prepared",
    "blocked": "blocked",
    "activated": "activated",
    "done": "done",
    "progress": "progress"
  },
  "de": {
    "terminated": "gekündigt",
    "in-termination": "in Kündigung",
    "active": "aktiv",
    "prepared": "vorbereitet",
    "blocked": "blockiert",
    "activated": "aktiviert",
    "done": "abgeschlossen",
    "progress": "in Bearbeitung"
  }
}
</i18n>
