<template>
  <data-table-extended
    :headers="headers"
    :items="variants"
    item-key="id"
    disable-sort
    :expanded="expanded"
    show-expand
    disable-pagination
    hide-default-footer
    :show-menu="!isNoneRecurringBillingPeriod"
    :show-header-menu="false"
  >
    <template v-slot:header.action.expand="{}">
      <v-list dense>
        <v-list-item @click="showMonthly = !showMonthly" dense>
          <v-list-item-icon>
            <v-icon v-if="showMonthly" small> mdi-toggle-switch </v-icon>
            <v-icon v-else small>mdi-toggle-switch-off</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ showMonthly ? $t("billing period") : $t("monthly") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>

    <template v-slot:item.billing_period="{ item }">
      <v-chip outlined small>{{ $t(product.billing_period) }}</v-chip>
    </template>

    <template v-slot:item.periodPrice="{ item }">
      <div class="monospace text-no-wrap">
        {{
          item.periodPrice != null ? formatMoney(item.periodPrice, "CHF") : "-"
        }}
      </div>
    </template>

    <template v-slot:item.periodPriceIncVatDiscount="{ item }">
      <div class="monospace text-no-wrap">
        {{
          item.periodPriceIncVatDiscount != null
            ? formatMoney(item.periodPriceIncVatDiscount, "CHF")
            : "-"
        }}
      </div>
    </template>

    <template v-slot:item.monthlyPriceOnce="{ item }">
      <div class="monospace text-no-wrap">
        {{
          item.monthlyPriceOnce != null
            ? formatMoney(item.monthlyPriceOnce, "CHF")
            : "-"
        }}
      </div>
    </template>

    <template v-slot:item.monthlyPriceRecurring="{ item }">
      <div class="monospace text-no-wrap">
        {{
          item.monthlyPriceRecurring != null
            ? formatMoney(item.monthlyPriceRecurring, "CHF")
            : "-"
        }}
      </div>
    </template>

    <template v-slot:item.discount="{ item }">
      <div class="monospace text-no-wrap">
        {{ item.discount != null ? formatNumber(item.discount, "%") : "-" }}
      </div>
    </template>

    <template v-slot:item.vat="{ item }">
      <div class="monospace text-no-wrap">
        {{ item.vat != null ? formatNumber(item.vat, "%") : "-" }}
      </div>
    </template>

    <template v-slot:item.monthlyPriceIncVatDiscount="{ item }">
      <div class="monospace text-no-wrap">
        {{
          item.monthlyPriceIncVatDiscount != null
            ? formatMoney(item.monthlyPriceIncVatDiscount, "CHF")
            : "-"
        }}
      </div>
    </template>

    <template v-slot:footer.page-text>
      <v-toolbar flat>
        <v-toolbar-title>Inhalt</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>

    <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
      <v-btn
        icon
        @click="expand(!isExpanded)"
        v-if="item.attributes.length > 0"
      >
        <v-icon>
          {{ isExpanded ? "mdi-chevron-up" : "mdi-chevron-down" }}
        </v-icon>
      </v-btn>
    </template>

    <template v-slot:expanded-item="{ item, headers }">
      <td :colspan="headers.length" class="py-4">
        <product-attributes :attributes="item.attributes" />
      </td>
    </template>

    <template v-slot:body.append>
      <tr class="inactive">
        <td :colspan="showMonthly ? 3 : 4">
          <v-layout justify-start>
            <div>
              {{
                showMonthly
                  ? $t("total monthly amount")
                  : isNoneRecurringBillingPeriod
                  ? $t("total unique amount")
                  : $t("total recurring amount")
              }}
            </div>
          </v-layout>
        </td>
        <td>
          <v-layout justify-end>
            <div class="monospace text-no-wrap" v-if="showMonthly">
              {{
                monthyProductPrice != null
                  ? formatMoney(monthyProductPrice, "CHF")
                  : "-"
              }}
            </div>
            <div class="monospace text-no-wrap" v-else>
              {{
                product.amount != null
                  ? formatMoney(product.amount, "CHF")
                  : "-"
              }}
            </div>
          </v-layout>
        </td>
        <td></td>
        <td>
          <v-layout justify-end>
            <div class="monospace text-no-wrap">
              {{ product.vat != null ? formatNumber(product.vat, "%") : "-" }}
            </div>
          </v-layout>
        </td>
        <td>
          <v-layout justify-end>
            <div
              class="monospace text-no-wrap font-weight-bold"
              v-if="showMonthly"
            >
              {{
                monthyProductPriceIncVat != null
                  ? formatMoney(monthyProductPriceIncVat, "CHF")
                  : "-"
              }}
            </div>
            <div class="monospace text-no-wrap font-weight-bold" v-else>
              {{
                getProductPriceIncVat(product) != null
                  ? formatMoney(getProductPriceIncVat(product), "CHF")
                  : "-"
              }}
            </div>
          </v-layout>
        </td>
        <td></td>
      </tr>
    </template>
  </data-table-extended>
</template>

<script>
import DataTableExtended from "@/components/basics/DataTableExtended.vue";
import ProductAttributes from "@/components/products/ProductAttributes";
import formatMoney from "@/utils/mixins/formatMoney";
import formatNumber from "@/utils/mixins/formatNumber";
import ProductTools from "@/utils/mixins/productTools";

export default {
  name: "ProductPrices",
  mixins: [formatMoney, formatNumber, ProductTools],
  components: {
    DataTableExtended,
    ProductAttributes,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    openAttr: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    showMonthly: true,
    expanded: [],
  }),
  watch: {
    product: {
      immediate: true,
      handler(value) {
        if (this.isYearlyBillingPeriod || this.isNoneRecurringBillingPeriod)
          this.showMonthly = false;

        if (this.openAttr)
          this.expanded = this.variants.filter(function (variant) {
            return variant.attributes.length > 0;
          });
      },
    },
  },
  computed: {
    isYearlyBillingPeriod() {
      return (
        this.product != null &&
        ["JIV", "WHO-JIV"].includes(this.product.billing_period)
      );
    },
    isNoneRecurringBillingPeriod() {
      return (
        this.product != null &&
        ["ER", "EN", "WHO-ER", "WHO-EN"].includes(this.product.billing_period)
      );
    },
    headers() {
      if (this.showMonthly)
        return [
          {
            text: this.$i18n.t("name"),
            value: "product_type_name",
          },
          {
            text: this.$i18n.t("number"),
            align: "right",
            value: "number",
          },
          {
            text: this.$i18n.t("initial"),
            align: "right",
            value: "monthlyPriceOnce",
          },
          {
            text: this.$i18n.t("monthly"),
            align: "right",
            value: "monthlyPriceRecurring",
          },
          {
            text: this.$i18n.t("discount"),
            align: "right",
            value: "discount",
          },
          {
            text: this.$i18n.t("vat"),
            align: "right",
            value: "vat",
          },
          {
            text: this.$i18n.t("monthly with vat"),
            align: "right",
            value: "monthlyPriceIncVatDiscount",
          },
          { text: "", value: "data-table-expand" },
        ];
      else
        return [
          {
            text: this.$i18n.t("name"),
            value: "product_type_name",
          },
          {
            text: this.$i18n.t("number"),
            align: "right",
            value: "number",
          },
          {
            text: this.$i18n.t("initial"),
            align: "right",
            value: "monthlyPriceOnce",
          },
          {
            text: this.$i18n.t("billing period"),
            align: "right",
            value: "billing_period",
          },
          {
            text: this.$i18n.t("amount"),
            align: "right",
            value: "periodPrice",
          },
          {
            text: this.$i18n.t("discount"),
            align: "right",
            value: "discount",
          },
          {
            text: this.$i18n.t("vat"),
            align: "right",
            value: "vat",
          },
          {
            text: this.$i18n.t("amount with vat"),
            align: "right",
            value: "periodPriceIncVatDiscount",
          },
          { text: "", value: "data-table-expand" },
        ];
    },
    monthyProductPrice() {
      if (
        ["ER", "EN", "WHO-ER", "WHO-EN"].includes(this.product.billing_period)
      )
        return 0.0;
      return this.getMonthyProductPrice(this.product);
    },
    monthyProductPriceIncVat() {
      if (
        ["ER", "EN", "WHO-ER", "WHO-EN"].includes(this.product.billing_period)
      )
        return 0.0;
      return this.getMonthyProductPriceIncVat(this.product);
    },
    variants() {
      var factor = this.billingPeriodToFactor(this.product.billing_period);
      var i = 0;
      return (
        this.product.variants
          // filter setup etc...
          .filter((variant) => variant.recurring || !variant.no_billing)
          .map(function (variant) {
            i += 1;
            var price = variant.amount == null ? variant.price : variant.amount;
            var monthlyPrice = price / factor;
            var discount = variant.discount != null ? variant.discount : 0.0;
            return {
              ...variant,
              id: i,
              periodPrice: price,
              periodPriceIncVatDiscount:
                price *
                (100.0 - discount) *
                0.01 *
                (100 + variant.vat) *
                0.01 *
                variant.number,
              monthlyPriceOnce: variant.recurring ? 0.0 : monthlyPrice,
              monthlyPriceRecurring: variant.recurring ? monthlyPrice : 0.0,
              monthlyPriceIncVatDiscount:
                monthlyPrice *
                (100.0 - discount) *
                0.01 *
                (100 + variant.vat) *
                0.01 *
                variant.number,
            };
          })
      );
    },
  },
};
</script>

<i18n>
{
  "en": {
    "name": "Name",
    "number": "Number",
    "initial": "Initial",
    "billing period": "Billing period",
    "monthly": "Monthly",
    "discount": "Discount",
    "vat": "VAT",
    "monthly with vat": "Monthly with VAT",
    "total monthly amount": "Total monthly amount",
    "amount": "Amount",
    "amount with vat": "Amount with VAT",
    "total recurring amount": "Total recurring amount",
    "total unique amount": "Total unique amount",
    "MIV": "monthly",
    "QIV": "quarterly",
    "HIV": "half-yearly",
    "JIV": "yearly",
    "ER": "setup (unique)",
    "EN": "unique",
    "WHO-MIV": "monthly",
    "WHO-QIV": "quarterly",
    "WHO-HIV": "half-yearly",
    "WHO-JIV": "yearly",
    "WHO-ER": "setup (unique)",
    "WHO-EN": "unique"
  },
  "de": {
    "name": "Name",
    "number": "Anzahl",
    "initial": "Erstmalig",
    "billing period": "Rechnungsperiode",
    "monthly": "Monatlich",
    "discount": "Rabatt",
    "vat": "MwSt.",
    "monthly with vat": "Monatlich inkl. MwSt.",
    "total monthly amount": "Summe monatliche Beträge",
    "amount": "Betrag",
    "amount with vat": "Betrag mit MwSt.",
    "total recurring amount": "Summe wiederkehrende Beträge",
    "total unique amount": "Summe einmalige Beträge",
    "MIV": "monatlich",
    "QIV": "quartalsweise",
    "HIV": "halbjährlich",
    "JIV": "jährlich",
    "ER": "Einrichtung (einmalig)",
    "EN": "einmalig",
    "WHO-MIV": "monatlich",
    "WHO-QIV": "quartalsweise",
    "WHO-HIV": "halbjährlich",
    "WHO-JIV": "jährlich",
    "WHO-ER": "Einrichtung (einmalig)",
    "WHO-EN": "einmalig"
    }
}
</i18n>
