var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isActive && _vm.isBbcsService && _vm.isWho)?_c('v-chip',{staticClass:"my-1 mr-1",attrs:{"to":{
      name: 'bbcs-details',
      params: { bbcsProductId: _vm.product.id },
    },"color":"secondary","link":""}},[_vm._v(" "+_vm._s(_vm.$t("BBCS internet"))+" ")]):_vm._e(),(_vm.isActive && _vm.isMetronetService && _vm.isWho)?_c('v-chip',{staticClass:"my-1 mr-1",attrs:{"to":{
      name: 'metronet-details',
      params: { metronetProductId: _vm.product.id },
    },"color":"secondary","link":""}},[_vm._v(" "+_vm._s(_vm.$t("Metronet internet"))+" ")]):_vm._e(),(_vm.isActive && _vm.isReverseDns)?_c('v-chip',{staticClass:"my-1 mr-1",attrs:{"to":{
      name: 'reverse-dns-service',
      params: { productId: _vm.product.id },
    },"color":"secondary","link":""}},[_vm._v(" "+_vm._s(_vm.$t("Reverse DNS"))+" ")]):_vm._e(),(_vm.isActive && _vm.isTrafficStats)?_c('v-chip',{staticClass:"my-1 mr-1",attrs:{"to":{
      name: 'rzo-traffic-stats',
      params: { productId: _vm.product.id },
    },"color":"secondary","link":""}},[_vm._v(" "+_vm._s(_vm.$t("Traffic stats"))+" ")]):_vm._e(),(_vm.isActive && _vm.isAccessLog)?_c('v-chip',{staticClass:"my-1 mr-1",attrs:{"to":{
      name: 'rzo-access-logs',
      params: { productId: _vm.product.id },
    },"color":"secondary","link":""}},[_vm._v(" "+_vm._s(_vm.$t("Access logs"))+" ")]):_vm._e(),(_vm.isActive && _vm.isPowerStats)?_c('v-chip',{staticClass:"my-1 mr-1",attrs:{"to":{
      name: 'rzo-power-stats',
      params: { productId: _vm.product.id },
    },"color":"secondary","link":""}},[_vm._v(" "+_vm._s(_vm.$t("Power stats"))+" ")]):_vm._e(),(
      _vm.isActive && (_vm.isVoipService || _vm.isMobileService) && _vm.isTelephoneService
    )?_c('v-chip',{staticClass:"my-1 mr-1",attrs:{"to":{
      name: 'phone-service-details',
      params: { productId: _vm.product.id },
    },"color":"secondary","link":""}},[_vm._v(" "+_vm._s(_vm.$t("Telephony"))+" ")]):_vm._e(),(_vm.isActive && _vm.isTVService)?_c('v-chip',{staticClass:"my-1 mr-1",attrs:{"to":{
      name: 'zuma-tv',
      params: { productId: _vm.product.id },
    },"color":"secondary","link":""}},[_vm._v(" "+_vm._s(_vm.$t("Television"))+" ")]):_vm._e(),(_vm.isActive && _vm.isDomainRegService)?_c('v-chip',{staticClass:"my-1 mr-1",attrs:{"to":{
      name: 'domain-service',
      params: { productId: _vm.product.id },
    },"color":"secondary","link":""}},[_vm._v(" "+_vm._s(_vm.$t("Domain"))+" ")]):(_vm.isActive && _vm.domainName)?_c('v-chip',{staticClass:"my-1 mr-1",attrs:{"to":{
      name: 'domain-service',
      params: { domainName: _vm.domainName },
    },"color":"secondary","link":""}},[_vm._v(" "+_vm._s(_vm.$t("Domain"))+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }