<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>{{ cardTitle }}</v-toolbar-title>
      <v-spacer />
      <v-tooltip v-if="inactive" top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" @click="
            inactive = false;
          options.page = 1;
          " :loading="loading" :disabled="loading" icon>
            <v-icon>mdi-toggle-switch</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("show active products") }}</span>
      </v-tooltip>
      <v-tooltip top v-else>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" @click="
            inactive = true;
          options.page = 1;
          " :loading="loading" :disabled="loading" icon>
            <v-icon>mdi-toggle-switch-off</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("show inactive products") }}</span>
      </v-tooltip>

      <v-menu bottom left>
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip top>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...menu }" :disabled="loading || productGroupsLoading"
                :loading="productGroupsLoading">
                <v-icon v-if="productGroupsInclude.length > 0">
                  mdi-filter-plus
                </v-icon>
                <v-icon v-else-if="productGroupsExclude.length > 0">
                  mdi-filter-minus
                </v-icon>
                <v-icon v-else> mdi-filter </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("filter for product categories") }}</span>
          </v-tooltip>
        </template>
        <v-list dense>
          <template v-for="(pg, i) in productGroups || []">
            <v-list-item :key="i" @click.stop="
              pg.filtered =
              pg.filtered == null ? true : pg.filtered ? false : null;
            productGroups = [...productGroups];
            " dense>
              <v-list-item-icon>
                <v-icon v-if="pg.filtered == null" small>
                  mdi-filter-off
                </v-icon>
                <v-icon v-else-if="pg.filtered" small>mdi-filter-plus</v-icon>
                <v-icon v-else small>mdi-filter-minus</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ pg.description }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" :loading="loadingExcel" :disabled="loadingExcel" @click="downloadExcel" icon>
            <v-icon>mdi-file-excel</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("download products Excel sheet") }}</span>
      </v-tooltip>
      <wiki slug="product-overview" />
    </v-toolbar>
    <v-card-actions class="mr-4 ml-4">
      <v-text-field v-model="search" append-outer-icon="mdi-magnify" clear-icon="mdi-close-circle" :disabled="loading"
        :clearable="!isMobile" v-bind:label="$t('search')" single-line hide-details @click:append-outer="getItems"
        @click:clear="search = ''" @keyup.enter.native="getItems" />
    </v-card-actions>
    <v-card-text>
      <data-table-extended :headers="headers" :items="products" :options.sync="options" :server-items-length="itemsTotal"
        :loading="loading" :disable-sort="loading" :mobile-breakpoint="mbreakpoint" sort-by="row"
        :hide-default-footer="itemsTotal <= 15" :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }" class="renderNewlines" show-expand show-menu local-storage-name="productList" open-expand-by-dbl-click-row>
        <template v-slot:item.billing_period="{ item }">
          <v-chip outlined small>{{ $t(item.billing_period) }}</v-chip>
        </template>

        <template v-slot:item.amount="{ item }">
          <div class="monospace text-no-wrap">
            {{ item.amount != null ? formatMoney(item.amount, "CHF") : "-" }}
          </div>
        </template>

        <template v-slot:item.amount_inc_vat="{ item }">
          <div class="monospace text-no-wrap">
            {{
              item.amount != null
              ? formatMoney(getProductPriceIncVat(item), "CHF")
              : "-"
            }}
          </div>
        </template>

        <template v-slot:item.monthly_amount="{ item }">
          <div class="monospace text-no-wrap">
            {{
              item.monthly_amount != null
              ? formatMoney(item.monthly_amount, "CHF")
              : "-"
            }}
          </div>
        </template>

        <template v-slot:item.monthly_amount_inc_vat="{ item }">
          <div class="monospace text-no-wrap">
            {{
              item.monthly_amount_inc_vat != null
              ? formatMoney(item.monthly_amount_inc_vat, "CHF")
              : "-"
            }}
          </div>
        </template>

        <template v-slot:item.vat="{ item }">
          <div class="monospace text-no-wrap">
            {{ item.vat != null ? formatNumber(item.vat, "%") : "-" }}
          </div>
        </template>

        <template v-slot:item.billing_start_date="{ item }">
          <div class="monospace">
            {{
              item.billing_start_date
              ? formatDate(item.billing_start_date)
              : "-"
            }}
          </div>
        </template>

        <template v-slot:item.billing_next_date="{ item }">
          <div class="monospace">
            {{
              getBillingNextDate(item)
              ? formatDate(getBillingNextDate(item))
              : "-"
            }}
          </div>
        </template>

        <template v-slot:item.billing_end_date="{ item }">
          <div class="monospace">
            {{
              item.billing_end_date ? formatDate(item.billing_end_date) : "-"
            }}
          </div>
        </template>

        <template v-slot:item.status="{ item }">
          <product-status :product="item" small />
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <product-details v-bind:productDetails="{ item }" />
          </td>
        </template>

        <template v-slot:item.row="{ item }">
          <v-edit-dialog v-if="!non_editable" :return-value.sync="item.row" @save="save(item, true)"
            :cancel-text="$t('cancel')" :save-text="$t('save')" large>
            <div class="editable">
              <v-icon small>mdi-pencil-outline</v-icon>
              <div>{{ item.row ? item.row : "-" }}</div>
            </div>
            <template v-slot:input>
              <validation-provider vid="row" :name="$t('row')" rules="numeric" v-slot="{ errors, valid, dirty, classes }">
                <v-text-field v-model="item.row" name="row" type="number" :label="$t('row')" :error-messages="errors"
                  :success="dirty && valid" :class="classes" single-line autofocus :clearable="!isMobile" full-width />
              </validation-provider>
            </template>
          </v-edit-dialog>
          <template v-else>
            {{ item.row ? item.row : "-" }}
          </template>
        </template>

        <template v-slot:item.description="{ item }">
          <v-edit-dialog v-if="!non_editable" :return-value.sync="item.description" @save="save(item)"
            :cancel-text="$t('cancel')" :save-text="$t('save')" large>
            <div class="editable">
              <v-icon small>mdi-pencil-outline</v-icon>
              <div>{{ item.description ? item.description : "-" }}</div>
            </div>
            <template v-slot:input>
              <validation-provider vid="description" :name="$t('description')" rules="max:100"
                v-slot="{ errors, valid, dirty, classes }">
                <v-textarea v-model="item.description" name="description" :label="$t('description')"
                  :error-messages="errors" :success="dirty && valid" :class="classes" autofocus counter="100"
                  :clearable="!isMobile" full-width rows="1" auto-grow />
              </validation-provider>
            </template>
          </v-edit-dialog>
          <template v-else>
            {{ item.description ? item.description : "-" }}
          </template>
        </template>

        <template v-slot:item.action="{ item }" v-if="!inactive">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" @click="
                $router.push({
                  name: 'product-details',
                  params: { productId: item.id },
                })
                " text small icon>
                <v-icon>mdi-eye-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("show product details") }}</span>
          </v-tooltip>
        </template>
      </data-table-extended>
    </v-card-text>
  </v-card>
</template>

<script>
import ProductDetails from "@/components/products/ProductListDetails";
import ProductStatus from "@/components/products/ProductStatus";
import formatMoney from "@/utils/mixins/formatMoney";
import formatDate from "@/utils/mixins/formatDate";
import formatNumber from "@/utils/mixins/formatNumber";
import downloadFile from "@/utils/mixins/downloadFile";
import ProductTools from "@/utils/mixins/productTools";
import isMobile from "@/utils/mixins/isMobile";
import DataTableExtended from "@/components/basics/DataTableExtended.vue";
import Wiki from "@/components/basics/Wiki";

export default {
  name: "Products",
  mixins: [
    formatMoney,
    formatDate,
    formatNumber,
    downloadFile,
    ProductTools,
    isMobile,
  ],
  components: {
    ProductDetails,
    ProductStatus,
    DataTableExtended,
    Wiki,
  },
  props: {
    customerId: {
      type: [String, Number],
      default: null,
      validator: (prop) =>
        typeof prop === "string" || typeof prop === "number" || prop === null,
    },
    non_editable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    products: [],
    loading: false,
    loadingExcel: false,
    inactive: false,
    itemsPerPage: 50,
    itemsTotal: 0,
    options: {},
    search: "",
    customer: null,
    productGroups: [],
    productGroupsLoading: false,
  }),
  computed: {
    query() {
      return this.$route.query["q"];
    },
    cardTitle() {
      this.customer;
      this.inactive;
      var title =
        (this.inactive ? this.$i18n.t("inactive") : this.$i18n.t("active")) +
        " " +
        this.$i18n.t("products");
      if (this.customer != null)
        title =
          title + " - " + this.customer.name + " #" + this.customer.number;
      return title;
    },
    headers() {
      var that = this;
      return [
        {
          text: this.$i18n.t("nr."),
          value: "row",
        },
        {
          text: this.$i18n.t("name"),
          value: "product_name",
        },
        {
          text: this.$i18n.t("description"),
          value: "description",
        },
        {
          text: this.$i18n.t("start billing"),
          align: "right",
          value: "billing_start_date",
          hidden: true,
        },
        {
          text: this.$i18n.t("next billing"),
          align: "right",
          value: "billing_next_date",
          hidden: this.inactive,
        },
        {
          text: this.$i18n.t("end billing"),
          align: "right",
          value: "billing_end_date",
          hidden: !this.inactive,
        },
        {
          text: this.$i18n.t("billing period"),
          value: "billing_period",
        },
        {
          text: this.$i18n.t("amount"),
          align: "right",
          value: "amount",
        },
        {
          text: this.$i18n.t("monthly amount"),
          align: "right",
          value: "monthly_amount",
          hidden: true,
        },
        {
          text: this.$i18n.t("vat"),
          align: "right",
          value: "vat",
          hidden: true,
        },
        {
          text: this.$i18n.t("amount with VAT"),
          align: "right",
          value: "amount_inc_vat",
          hidden: true,
          sortable: false,
        },
        {
          text: this.$i18n.t("monthly amount with VAT"),
          align: "right",
          value: "monthly_amount_inc_vat",
          hidden: true,
        },
        {
          text: this.$i18n.t("status"),
          value: "status",
          hidden: true,
          sortable: false,
        },
        {
          text: this.$i18n.t("commission code"),
          value: "commission_code",
          hidden: true,
        },
        {
          text: this.$i18n.t("commission descr"),
          value: "commission_descr",
          hidden: true,
        },
        { text: "", value: "data-table-expand" },
        { text: "", value: "action", sortable: false },
      ];
    },
    productGroupsInclude() {
      return this.productGroups
        .filter(function (pg) {
          if (pg.filtered == true) return pg;
        })
        .map(function (pg) {
          return pg.id;
        });
    },
    productGroupsExclude() {
      return this.productGroups
        .filter(function (pg) {
          if (pg.filtered == false) return pg;
        })
        .map(function (pg) {
          return pg.id;
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
    "$store.state.session": function () {
      this.search = "";
      this.getItems();
    },
    inactive: "getItems",
    search() {
      var that = this;
      clearTimeout(that.delayTimer);
      that.delayTimer = setTimeout(function () {
        that.options.page = 1;
        that.getItems();
      }, 850);
    },
    customerId(value) {
      if (value != null) {
        this.products = [];
        this.customer = null;
        this.getItems();
        this.getOptionalCustomerDetails();
      }
    },
    productGroups() {
      this.getItems();
    },
  },
  methods: {
    getParams() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      var order = null;
      switch (sortBy.toString()) {
        case "product_number":
          order = 2;
          break;
        case "billing_period":
          order = 3;
          break;
        case "billing_start_date":
          order = 4;
          break;
        case "billing_end_date":
          order = 5;
          break;
        case "billing_next_date":
          order = 6;
          break;
        case "discount":
          order = 7;
          break;
        case "amount":
          order = 11;
          break;
        case "description":
          order = 12;
          break;
        case "product_name":
          order = 13;
          break;
        case "row":
          order = 20;
          break;
        case "vat":
          order = 27;
          break;
        case "monthly_amount":
          order = 30;
          break;
        case "monthly_amount_inc_vat":
          order = 31;
          break;
        case "commission_code":
          order = 33;
          break;
        case "commission_descr":
          order = 34;
          break;
      }
      var params = {
        active: !this.inactive,
        page_size: itemsPerPage,
        page_number: page - 1,
        order_by: order,
        order_desc: sortDesc.toString(),
        q: this.search,
        customer_id: this.customerId || null,
        group_ids_include: this.productGroupsInclude.join(",") || null,
        group_ids_exclude: this.productGroupsExclude.join(",") || null,
      };
      return params;
    },
    getProductGroups() {
      var that = this;
      this.productGroupsLoading = true;
      this.$http
        .get("products/groups", {
          params: { own_groups: true },
        })
        .then((response) => {
          that.productGroups = response.data;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(function () {
          that.productGroupsLoading = false;
        });
    },
    getItems() {
      var that = this;
      this.loading = true;
      this.$http
        .get("products", { params: this.getParams() })
        .then((response) => {
          that.products = response.data.results;
          that.itemsTotal = response.data.count;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(function () {
          that.loading = false;
        });
    },
    save(item, reload) {
      var that = this;
      this.$http
        .patch("products/" + item.id, {
          description: item.description,
          row: item.row,
        })
        .then((response) => {
          this.$snotify.success(this.$i18n.t("product description saved"));
          if (reload) {
            that.getItems();
          }
        })
        .catch((err) => {
          if (err.invalid()) {
            that.$store.commit("setSystemError", {
              msg: err.message,
              title: err.title,
            });
          }
        });
    },
    downloadExcel() {
      var that = this;
      var params = this.getParams();
      this.loadingExcel = true;
      this.downloadFile(
        "products/xls",
        "get",
        params,
        this.$i18n.t("products.xlsx"),
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        function () {
          that.loadingExcel = false;
        }
      );
    },
    getOptionalCustomerDetails() {
      if (this.customerId != null) {
        var that = this;
        this.$http
          .get("customers/" + this.customerId)
          .then((response) => {
            that.customer = response.data;
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
  },
  mounted() {
    this.search = this.query || "";
    // this.getItems();
    this.getOptionalCustomerDetails();
    this.getProductGroups();
  },
};
</script>

<i18n>
{
  "en": {
    "download": "Download",
    "active": "Active",
    "inactive": "Inactive",
    "products": "products",
    "search": "Search",
    "inactive products": "Inactive products",
    "nr.": "Nr.",
    "name": "Name",
    "description": "Description",
    "row": "Row",
    "start billing": "Start billing",
    "next billing": "Next billing",
    "end billing": "End billing",
    "amount": "Amount",
    "amount with VAT": "Amount with VAT",
    "monthly amount": "Monthly amount",
    "monthly amount with VAT": "Monthly amount with VAT",
    "vat": "VAT",
    "status": "Status",
    "Input too long!": "Input too long",
    "Input not a number": "Input not a number",
    "cancel": "Cancel",
    "save": "Save",
    "after termination": "after termination",
    "products.xlsx": "products.xlsx",
    "show active products": "Show active products",
    "show inactive products": "Show inactive products",
    "download products Excel sheet": "Download products Excel sheet",
    "show product details": "Show product details",
    "product description saved": "Product description saved",
    "product categories": "Product categories",
    "billing period": "Billing period",
    "commission code": "Commission code",
    "commission descr": "Commission description",
    "filter for product categories": "filter for product categories",
    "MIV": "monthly",
    "QIV": "quarterly",
    "HIV": "half-yearly",
    "JIV": "yearly",
    "ER": "setup (unique)",
    "EN": "unique",
    "WHO-MIV": "monthly",
    "WHO-QIV": "quarterly",
    "WHO-HIV": "half-yearly",
    "WHO-JIV": "yearly",
    "WHO-ER": "setup (unique)",
    "WHO-EN": "unique"
  },
  "de": {
    "download": "Download",
    "active": "aktive",
    "inactive": "inaktive",
    "products": "Produkte",
    "search": "suchen",
    "inactive products": "inaktive Produkte",
    "nr.": "Nr.",
    "name": "Name",
    "description": "Beschreibung",
    "row": "Zeile",
    "start billing": "Verrechnungsbeginn",
    "next billing": "Nächste Verrechnung",
    "end billing": "Verrechnungsende",
    "amount": "Betrag",
    "amount with VAT": "Betrag mit MwSt.",
    "monthly amount": "Monatlicher Betrag",
    "monthly amount with VAT": "Monatlicher Betrag mit MwSt.",
    "vat": "MwSt.",
    "status": "Status",
    "Input too long!": "Eingabe ist zu lang",
    "Input not a number": "Eingabe ist keine Zahl",
    "cancel": "abbrechen",
    "save": "speichern",
    "after termination": "nach Kündigung",
    "products.xlsx": "Produkte.xlsx",
    "show active products": "zeige aktive Produkte",
    "show inactive products": "zeige inaktive Produkte",
    "download products Excel sheet": "Download Produkte Excel Sheet",
    "show product details": "zeige Produkt Details",
    "product description saved": "Produktbeschreibung gespeichert",
    "product categories": "Produkt Kategorien",
    "billing period": "Rechnungsperiode",
    "commission code": "Kommission Code",
    "commission descr": "Kommission Beschr.",
    "filter for product categories": "nach Produkt Kategorien filtern",
    "MIV": "monatlich",
    "QIV": "quartalsweise",
    "HIV": "halbjährlich",
    "JIV": "jährlich",
    "ER": "Einrichtung (einmalig)",
    "EN": "einmalig",
    "WHO-MIV": "monatlich",
    "WHO-QIV": "quartalsweise",
    "WHO-HIV": "halbjährlich",
    "WHO-JIV": "jährlich",
    "WHO-ER": "Einrichtung (einmalig)",
    "WHO-EN": "einmalig"
  }
}
</i18n>

<style scoped>
.min-sizing {
  min-width: 32px;
  min-height: 32px;
}

.editable {
  display: flex;
  align-items: center;
}
</style>
