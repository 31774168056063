<template>
  <div>
    <v-progress-linear :active="loading" indeterminate></v-progress-linear>
    <product-prices v-if="product" :product="product" class="my-4" />
    <product-tags v-if="product" :product="product" class="mb-4" />
  </div>
</template>

<script>
import ProductPrices from "@/components/products/ProductPrices";
import ProductTags from "@/components/products/ProductTags";

export default {
  name: "ProductDetails",
  props: ["productDetails"],
  components: {
    ProductPrices,
    ProductTags,
  },
  data: () => ({
    loading: false,
    product: null,
  }),
  mounted() {
    this.object = this.$props.productDetails.item;
    var that = this;
    if (this.object.id != null) {
      that.loading = true;
      that.$http
        .get("products/" + this.object.id)
        .then((response) => {
          that.product = response.data;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(function() {
          that.loading = false;
        });
    }
  },
};
</script>
